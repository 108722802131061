import React from 'react';
import { Helmet } from 'react-helmet';
import CssHeader from './CssHeader';
import Header from './Header';
import Footer from './Footer';
import IncludeSidebar from './IncludeSidebar';
import IncludeMariigeCertificate from "./IncludeMariigeCertificate";

function MarriageCertificate() {
  return (
    <>
      <Helmet>
        <title>Court Marriage</title>
        <meta name="description" content="Court Marriage." />
        <meta name="keywords" content="Delhi Court Marriage" />
      </Helmet>
      <CssHeader />
      <Header />
      <div id="main" className="site-main hfeed site">
        <div id="main-content" className="main-content">
          <div id="primary" className="content-area">
            <div id="content" className="site-content" role="main">
              <div id="post-52" className="post-52 page type-page status-publish hentry">
                <header className="entry-header">
                  <h1 className="entry-title">Marriage Certificate</h1>
                </header>
                <div className="entry-content">
                  <div className="fl-builder-content fl-builder-content-52 fl-builder-content-primary fl-builder-global-templates-locked" data-post-id="52">
                    <div className="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5f2fa53b6bc02" data-node="5f2fa53b6bc02">
                      <div className="fl-row-content-wrap">
                        <div className="fl-row-content fl-row-fixed-width fl-node-content">
                          <div className="fl-col-group fl-node-5f2fa53b7eb56" data-node="5f2fa53b7eb56">
                            <div className="fl-col fl-node-5f2fa53b7ec58" data-node="5f2fa53b7ec58">
                              <div className="fl-col-content fl-node-content">
                                <div className="fl-module fl-module-callout fl-node-5f2fa53b6ba9b" data-node="5f2fa53b6ba9b">
                                  <div className="fl-module-content fl-node-content">
                                    <div className="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                                      <div className="fl-callout-content">
                                        <div className="fl-module fl-module-callout fl-node-5fb7e9881a6bf" style={{ border: '0px', fontFamily: 'Lato, sans-serif', fontSize: '16px', margin: '0px', outline: '0px', padding: '0px', verticalAlign: 'baseline', color: '#2b2b2b', backgroundColor: '#ffffff', WebkitTapHighlightColor: 'transparent' }} data-node="5fb7e9881a6bf">
                                          <div className="fl-module-content fl-node-content" style={{ border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                            <div className="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title" style={{ border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                              <div className="fl-callout-content" style={{ border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                <h3 className="fl-callout-title"><span className="fl-callout-title-text">Note</span></h3>
                                                <div className="fl-callout-text-wrap">
                                                  <div className="fl-callout-text">
                                                    <p>Govt. I.T. department time to time upgrade/change/update the format/design of the certificate, for example see any old and new Passport/Driving License/Voter ID Card etc.</p>
                                                    <p>
                                                      <img decoding="async" className="alignnone size-medium wp-image-86" src="/static/img/crtm1-212x300.png" alt="" width="212" height="300" />
                                                      <img decoding="async" loading="lazy" className="alignnone size-medium wp-image-87" src="/static/img/crtm2-212x300.png" alt="" width="212" height="300" />
                                                      <img decoding="async" loading="lazy" className="alignnone size-medium wp-image-88" src="/static/img/crtm3-212x300.png" alt="" width="212" height="300" />
                                                      <img decoding="async" loading="lazy" className="alignnone size-medium wp-image-89" src="/static/img/crtm4-212x300.png" alt="" width="212" height="300" />
                                                      <img decoding="async" loading="lazy" className="alignnone size-medium wp-image-91" src="/static/img/crtm6-205x300.png" alt="" width="205" height="300" />
                                                      <img decoding="async" loading="lazy" className="alignnone size-medium wp-image-92" src="/static/img/crtm7-212x300.png" alt="" width="212" height="300" />
                                                      <img decoding="async" loading="lazy" className="alignnone size-medium wp-image-93" src="/static/img/crtm6-1-205x300.png" alt="" width="205" height="300" />
                                                    </p>
                                                    <p>&nbsp;</p>
                                                  </div>
                                                </div>
                                                <h3 className="fl-callout-title" style={{ overflowWrap: 'break-word', border: '0px', fontFamily: 'inherit', fontSize: '22px', fontStyle: 'inherit', fontWeight: 'bold', margin: '0px 0px 12px', outline: '0px', padding: '0px', verticalAlign: 'baseline', lineHeight: '1.09091', WebkitTapHighlightColor: 'transparent' }}>
                                                  <span className="fl-callout-title-text" style={{ border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>Marriage Certificate</span>
                                                </h3>
                                                <div className="fl-callout-text-wrap" style={{ border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                  <div className="fl-callout-text" style={{ border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                    <div className="fl-callout-text-wrap" style={{ border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                      <div className="fl-callout-text" style={{ border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                        <p style={{ overflowWrap: 'break-word', border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px 0px 24px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                          <span style={{ border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'bold', margin: '0px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>Note:</span> Our Expert Will Examine Your Documents and, After Checking, Will Advise You for Registration Process. Process Needs (Approx 30 Days) Days After Completion of the Registration Process.
                                                        </p>
                                                        <h3 style={{ overflowWrap: 'break-word', border: '0px', fontFamily: 'inherit', fontSize: '22px', fontStyle: 'inherit', fontWeight: 'bold', margin: '0px 0px 12px', outline: '0px', padding: '0px', verticalAlign: 'baseline', lineHeight: '1.09091', WebkitTapHighlightColor: 'transparent' }}>
                                                          <span style={{ border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>Marriage Certificate – Mandatory for:</span>
                                                        </h3>
                                                        <ul>
                                                          <li style={{ overflowWrap: 'break-word', border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px 0px 8px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                            Legal Purpose.
                                                          </li>
                                                          <li style={{ overflowWrap: 'break-word', border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px 0px 8px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                            Bank Accounts.
                                                          </li>
                                                          <li style={{ overflowWrap: 'break-word', border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px 0px 8px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                            Change of Name Etc.
                                                          </li>
                                                        </ul>
                                                        <h3 style={{ overflowWrap: 'break-word', border: '0px', fontFamily: 'inherit', fontSize: '22px', fontStyle: 'inherit', fontWeight: 'bold', margin: '0px 0px 12px', outline: '0px', padding: '0px', verticalAlign: 'baseline', lineHeight: '1.09091', WebkitTapHighlightColor: 'transparent' }}>
                                                          <span style={{ border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>Procedure of Marriage Registration:</span>
                                                        </h3>
                                                        <p style={{ overflowWrap: 'break-word', border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px 0px 24px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                          Marriage Certificate is the Documentary Evidence of Marriage. Marriage Certificate is Mandatory For Obtaining Visa and Applying For Visa and Necessary For Wife/Husband Name Change Etc.
                                                        </p>
                                                        <ul>
                                                          <li style={{ overflowWrap: 'break-word', border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px 0px 8px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                            (30 Days) Notice to be Given to the Marriage Officer in Whose Jurisdiction One of the Parties Reside For a Period Not Less Than (30 Days) Immediately Preceding The Date of Notice.
                                                          </li>
                                                          <li style={{ overflowWrap: 'break-word', border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px 0px 8px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                            Marriage Certificate is the Legal Proof of Marriage.
                                                          </li>
                                                          <li style={{ overflowWrap: 'break-word', border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px 0px 8px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                            Every Marrying Couple Need to Submit Their KYC Id Proofs as Address Proof and Date of Birth Proof, Witness (3) For Registration of Marriage.
                                                          </li>
                                                          <li style={{ overflowWrap: 'break-word', border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px 0px 8px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                            Along With All the Documents Two Passport Size Photos.
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <h3 style={{ overflowWrap: 'break-word', border: '0px', fontFamily: 'inherit', fontSize: '22px', fontStyle: 'inherit', fontWeight: 'bold', margin: '0px 0px 12px', outline: '0px', padding: '0px', verticalAlign: 'baseline', lineHeight: '1.09091', WebkitTapHighlightColor: 'transparent' }}>
                                                  <span style={{ border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>Required Documents for Marriage Certificate (Registration):</span>
                                                </h3>
                                                <p style={{ overflowWrap: 'break-word', border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px 0px 24px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                  <span style={{ border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'bold', margin: '0px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>Required Documents:</span> Affidavit: The Affidavit Must Include Details Such as Date of Marriage, Marital Status, and Nationality.
                                                </p>
                                                <ul>
                                                  <li style={{ overflowWrap: 'break-word', border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px 0px 8px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                    Address Proof: Voter ID, Ration Card, Passport, Driving License.
                                                  </li>
                                                  <li style={{ overflowWrap: 'break-word', border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px 0px 8px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                    Age Proof: Birth Certificate, 10th Class Marksheet.
                                                  </li>
                                                  <li style={{ overflowWrap: 'break-word', border: '0px', fontFamily: 'inherit', fontStyle: 'inherit', fontWeight: 'inherit', margin: '0px 0px 8px', outline: '0px', padding: '0px', verticalAlign: 'baseline', WebkitTapHighlightColor: 'transparent' }}>
                                                    Affidavit of Notary (Affidavit Must Be Attested By Notary).
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <IncludeSidebar/>
        </div>
        <IncludeMariigeCertificate/>
      </div>
      <Footer />
    </>
  );
}

export default MarriageCertificate;
