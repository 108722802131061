import React from 'react';
import { Helmet } from 'react-helmet';
import CssHeader from './CssHeader';
import Header from './Header';
import Footer from './Footer';
import IncludeSidebar from './IncludeSidebar';
import IncludeMariigeCertificate from "./IncludeMariigeCertificate";

function PrivacyPolicy() {
	return (
		<>
		  <Helmet>
			<title>Court Marriage- Privacy Policy</title>
			<meta name="description" content="Court Marriage- Privacy Policy" />
			<meta name="keywords" content="Delhi Court Marriage - Privacy Policy" />
		  </Helmet>
		  <CssHeader />
		  <Header />
		  <div id="main" className="site-main hfeed site">
			<div id="main-content" className="main-content">
			  <div id="primary" className="content-area">
				<div id="content" className="site-content" role="main">				 
				
					<div id="post-52" className="post-52 page type-page status-publish hentry">
						<header class="entry-header">
							<h1 class="entry-title">Privacy Policy</h1>
						</header>
						<div class="entry-content">
							<div class="fl-callout-content">
								<p class="fl-callout-title">
									<span class="fl-callout-title-text">At Court Marriage, we respect your privacy and ensure the confidentiality of your personal information during the court marriage process.
									</span>	
								</p>
                                    <p>
									Data Protection: Your personal details are securely stored and used only for legal marriage procedures.
									</p>
									<p>
									No Third-Party Sharing: We do not share your information with unauthorized entities.
									</p>
									<p>
									Confidentiality: All communication and documentation remain private and protected.
									</p>
									<p>
									Legal Compliance: We adhere to Indian laws and regulations for data security.
									</p>
									<p>
									For any concerns, contact us at info@courtmarriage-gov.co.in.
									</p>
								
							</div>
						</div>
					</div>
					
				</div>
			  </div>
			  <IncludeSidebar/>
			</div>
			<IncludeMariigeCertificate/>
		  </div>
		  <Footer />
		</>
	  );
	}
	

export default PrivacyPolicy;
