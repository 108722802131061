import React from 'react';
import { Helmet } from 'react-helmet';
import CssHeader from './CssHeader';
import Header from './Header';
import Footer from './Footer';
import IncludeSidebar from './IncludeSidebar';
import IncludeMariigeCertificate from "./IncludeMariigeCertificate";

function TermsConditions() {
	return (
		<>
		  <Helmet>
			<title>Court Marriage - Terms Conditions</title>
			<meta name="description" content="Court Marriage - Terms Conditions" />
			<meta name="keywords" content="Delhi Court Marriage - Terms Conditions" />
		  </Helmet>
		  <CssHeader />
		  <Header />
		  <div id="main" className="site-main hfeed site">
			<div id="main-content" className="main-content">
			  <div id="primary" className="content-area">
				<div id="content" className="site-content" role="main">				 
				
					<div id="post-52" className="post-52 page type-page status-publish hentry">
						<header class="entry-header">
							<h1 class="entry-title">Terms Conditions</h1>
						</header>
						<div class="entry-content">
							<div class="fl-callout-content">
								<p class="fl-callout-title">
									<span class="fl-callout-title-text">Welcome to Court Marriage. By using our court marriage services, you agree to abide by the following terms and conditions:
									</span>	
								</p>
                                    <p>
									<b>Eligibility: </b>Both parties must meet the legal marriageable age: 18 years for females and 21 years for males.<br /><br />
										The marriage must comply with the Special Marriage Act, Hindu Marriage Act, or any other applicable law.
									</p>
									 <p>
									<b>Documentation: </b>Applicants must provide valid identity proof, address proof, passport-size photographs, and required affidavits.<br /><br />
									Any incorrect, incomplete, or misleading information may lead to rejection of the application.
									</p>
									
									 <p>
									<b>Fees & Payments: </b>All payments, including government fees and service charges, must be made in advance.<br /><br />
									Fees are subject to change based on legal and administrative updates.
									</p>
									
									 <p>
									<b>Processing Time: </b>The court marriage process follows legal timelines and government procedures.<br /><br />
										We are not responsible for delays caused by legal requirements or government authorities.
									</p>
									
									 <p>
									<b>Cancellation & Refund Policy: </b>Fees once paid are generally non-refundable.<br /><br />
									Partial refunds may be considered only if the service is canceled before initiation.<br /><br />
										No refunds will be issued for rejection due to incomplete or incorrect documents.
									</p>
									
									
									<p>
									<b>Legal Compliance: </b>We strictly adhere to Indian marriage laws and do not support fraudulent or unlawful marriages. 
									<br /><br />
									Any misrepresentation or illegal activity will lead to service termination without a refund.
									</p>
									
									<p>
									<b>Dispute Resolution: </b>Any disputes arising out of the service will be subject to Indian laws and the jurisdiction of Delhi courts.
									</p>
									
									
									<p>
									For more information, contact us at info@courtmarriage-gov.co.in.
									</p>
								
							</div>
						</div>
					</div>
					
				</div>
			  </div>
			  <IncludeSidebar/>
			</div>
			<IncludeMariigeCertificate/>
		  </div>
		  <Footer />
		</>
	  );
	}
	

export default TermsConditions;
